import React from 'react';

import { gql, QueryResult, useMutation, useQuery } from '@apollo/client';
import { RouteComponentProps } from '@reach/router';

import { OneColumn } from '@components/Layouts/OneColumn';
import LinkPrevious from '@components/LinkPrevious';
import Loading from '@components/Loading';
import Title from '@components/Title';

import ChangeSecurityQuestionForm, { FormValues } from './Form';
import SecurityQuestionInstructions from './SecurityQuestionInstructions';

import { useNotifications } from '@use-cases/notifications';

import { localizedNavigate } from '@utils/localized-navigate';

import { useTranslation } from '@external/react-i18next';

import {
  FetchSecurityQuestionsQuery,
  FetchSecurityQuestionsQueryVariables,
  UpdateSecurityQuestionMutation,
} from '@typings/operations';

export const useFetchSecurityQuestionsQuery = (): Pick<
  QueryResult<FetchSecurityQuestionsQuery>,
  'data' | 'error' | 'loading'
> =>
  useQuery<
    FetchSecurityQuestionsQuery,
    FetchSecurityQuestionsQueryVariables
  >(gql`
    query fetchSecurityQuestions {
      currentUser {
        availableSecurityQuestions {
          id
          text
        }
      }
    }
  `);

export const useUpdateSecurityQuestionMutation = () => {
  return useMutation<UpdateSecurityQuestionMutation>(
    gql`
      mutation updateSecurityQuestion(
        $currentPassword: String!
        $question: String!
        $answer: String!
      ) {
        changeSecurityQuestion(
          currentPassword: $currentPassword
          question: $question
          answer: $answer
        )
      }
    `,
    {
      refetchQueries: ['AuthGetUser'],
      awaitRefetchQueries: true,
    }
  );
};

interface Props extends RouteComponentProps {}
const ChangeSecurityQuestion: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { addSuccess } = useNotifications();
  const { data, loading, error } = useFetchSecurityQuestionsQuery();
  const [
    updateSecurityQuestion,
    { loading: mutLoading, error: errorUpdateSecurityQuestion },
  ] = useUpdateSecurityQuestionMutation();

  if (!data || loading || mutLoading || error) {
    return <Loading />;
  }

  const availableSecurityQuestions =
    data?.currentUser?.availableSecurityQuestions || [];
  const handleSubmit = async ({
    question,
    answer,
    currentPassword,
  }: FormValues) => {
    const mutVariables = {
      currentPassword,
      answer,
      question: availableSecurityQuestions.filter(
        ({ id }) => id === question
      )[0].text,
    };

    await updateSecurityQuestion({
      variables: mutVariables,
    });

    if (!errorUpdateSecurityQuestion) {
      addSuccess(
        t(
          'account-settings.change-security-question.success-message',
          'Success! Your new security question and answer have been saved.'
        ),
        { id: 'form.success' }
      );
      localizedNavigate(`/account/settings`);
    }
  };

  return (
    <OneColumn className="mb-20">
      <LinkPrevious
        path="/account/settings"
        label={t('account.back-link.label', 'Account Settings')}
      />
      <Title>
        {t(
          'account-settings.change-security-question.title',
          'Change Security Question'
        )}
      </Title>
      <p className="text-big">
        {t(
          'account-settings.change-security-question.info',
          'This security question will be used to help us verify your identity if you need to reset your password.'
        )}
      </p>
      <SecurityQuestionInstructions />
      <ChangeSecurityQuestionForm
        availableQuestions={availableSecurityQuestions}
        handleSubmit={handleSubmit}
        handleCancel={() => localizedNavigate('/account/settings')}
      />
    </OneColumn>
  );
};

export default ChangeSecurityQuestion;
