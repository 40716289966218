import React, { useEffect, useState } from 'react';

import { RouteComponentProps } from '@reach/router';
import { Form, Formik } from 'formik';
import * as yup from 'yup';

import { Button } from '@components/Button';
import RadioField from '@components/Formik/RadioField';
import {
  isRequired,
  schemaField,
} from '@components/Formik/validation/schemaDefinitions';
import { OneColumn } from '@components/Layouts/OneColumn';
import Loading from '@components/Loading';

import { drupalAuthentication } from './drupalAuthentication';

import { User } from '@domain/auth/types';

import { useNotifications } from '@use-cases/notifications';

import { useCreateIndividualFromSession } from '@repositories/auth/hooks';
import { useBasicInfo } from '@repositories/basicInfo';
import { useDISLanguages } from '@repositories/disLanguage/hooks/useDISLanguages';

import { getDISLanguageFromTwoLetterCode } from '@utils/languages';
import { localizedNavigate } from '@utils/localized-navigate';

import { useTranslation } from '@external/react-i18next';
import { useAppConfig } from '@hooks/appConfig';
import { useLogger } from '@hooks/logger';

import { DIS } from '@typings/dis';
import { FEATURE_LOGIN_AUTH_CONTROLLER, isEnabled } from '@utils/features';

enum MemberStatus {
  Rotary = 'rotary',
  Rotaract = 'rotaract',
  None = 'none',
}

interface FormValues {
  memberStatus?: MemberStatus;
}

interface Props extends RouteComponentProps {}
const SelectMemberStatus: React.FC<Props> = () => {
  const { t, i18n } = useTranslation();
  const {
    user = { individualId: '', isLoggedIn: false },
    refetch,
  } = useAppConfig();
  const { addLog } = useLogger();
  const langcode = i18n.language;
  const { addInfo, addSuccess } = useNotifications();
  const [basicInfo] = useBasicInfo();
  const { data, loading } = useDISLanguages();
  const [createIndividualFromSession] = useCreateIndividualFromSession();
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    if (submitted) {
      return;
    }
    if (user?.individualId) {
      localizedNavigate('/');
    } else {
      addInfo(
        t(
          'select-member-status.info-notification',
          'Welcome to My Rotary! Answer the following questions to finish setting up your account'
        )
      );
    }
  }, [user, submitted]);

  if (loading || !data || submitted) {
    return <Loading />;
  }

  const options = [
    {
      value: MemberStatus.Rotary,
      label: t(
        'select-member-status.form.option1',
        `I'm a current or former member of a Rotary Club`
      ),
    },
    {
      value: MemberStatus.Rotaract,
      label: t(
        'select-member-status.form.option2',
        `I'm a current or former member of a Rotaract Club`
      ),
    },
    {
      value: MemberStatus.None,
      label: t(
        'select-member-status.form.option3',
        `I have never been a member of any Rotary Club or Rotaract Club`
      ),
    },
  ];
  const handleSubmit = async ({ memberStatus }: FormValues) => {
    setSubmitted(true);
    if (
      memberStatus === MemberStatus.Rotaract ||
      memberStatus === MemberStatus.Rotary
    ) {
      // forward to email form
      localizedNavigate('/account/map-by-email');
    } else {
      await createIndividualFromSession({
        variables: {
          disLangcode: getDISLanguageFromTwoLetterCode(data.languages, langcode)
            .id,
        },
      });

      const successMessage = t(
        'account-mapping.create-individual-from-session.success',
        'Success! Your My Rotary account is now setup.'
      );

      let userInfo = null;
      const authControllerEnabled = isEnabled(FEATURE_LOGIN_AUTH_CONTROLLER);
      if (authControllerEnabled) {
        const emailId = (user as User)?.login;

        if (emailId) {
          const basicUserInfo = await basicInfo({
            variables: { emailId },
          });
          userInfo = (basicUserInfo?.data
            ?.basicInfo as unknown) as DIS.SSOTicketResponse;
        }
      }
      drupalAuthentication(
        successMessage,
        addLog,
        user,
        i18n,
        addSuccess,
        userInfo
      );

      // Update user data
      refetch();
      localizedNavigate('/');
    }
  };
  return (
    <OneColumn>
      <h1>{t('select-member-status.title', 'Your Member Status')}</h1>
      <h3>
        {t('select-member-status.sub-title', 'Tell us your member status.')}
      </h3>
      <Formik
        initialValues={{ memberStatus: undefined } as FormValues}
        validationSchema={yup.object().shape({
          memberStatus: schemaField(t, yup.string(), isRequired),
        })}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <RadioField
              name="memberStatus"
              label={t(
                'select-member-status.form.member-status-label',
                'What is your member status?'
              )}
              required
              options={options}
            />
            <p>
              {t(
                'select-member-status.form.info',
                'Note: If you belong to both a Rotary Club and Rotaract Club, you can choose either option.'
              )}
            </p>
            <Button disabled={isSubmitting}>
              {t('select-member-status.form.button-label', 'Continue')}
            </Button>
          </Form>
        )}
      </Formik>
    </OneColumn>
  );
};

export default SelectMemberStatus;
